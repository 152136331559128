import React, { ReactNode } from "react";
import clsx from "clsx"

interface NoteProps {
  children: ReactNode;
  className?: string;
  color?: string;
  headerText: string;
  type?: "neutral" | "success" | "warning" | "error";
  withIcon?: boolean;
}

export const Note = ({
  children,
  className,
  color,
  headerText,
  type = "neutral",
  withIcon = true,
}: NoteProps) => {
  const isMs: boolean = color === "ms"

  const iconTypeLookup = {
    "neutral": "fas fa-comment",
    "success": "fas fa-check",
    "warning": "fas fa-question",
    "error": "fas fa-exclamation-circle",
  }

  const iconColor: string =
    type === "neutral" && isMs
      ? "text-purple-500"
      : type === "neutral" && !isMs
        ? "text-indigo-500"
        : "";

  return (
    <div
      className={
        clsx(`${className} p-5 mt-5 leading-relaxed text-gray-700 rounded-lg md:px-6 md:pt-5 md:pb-3 tracking-wider`, {
          "bg-purple-100": type === "neutral" && isMs,
          "bg-indigo-100": type === "neutral" && !isMs,
          "text-textSuccess bg-lime-100": type === "success",
          "text-textWarning bg-yellow-100": type === "warning",
          "text-textError bg-red-200": type === "error",
        })}
      color={color}
    >
      <div className="flex items-center space-x-4">
        {withIcon &&
          <i className={`${iconTypeLookup[type]} ${iconColor} text-lg`} />
        }
        <span className="text-lg font-bold">{headerText}</span>
      </div>
      <div className="mt-2">
        {children}
      </div>
    </div>
  )
};

export default Note;

